<template lang="pug">
  .print_log_wrapper
    | 打印日志
</template>

<script>
export default {
  name: "index"
}
</script>

<style scoped lang="stylus">

</style>